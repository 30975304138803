
window.onload = async () => {
	document.querySelector("#loginbutton")?.addEventListener("click", () => {
		let name     = (document.querySelector("#namefield") as HTMLInputElement).value
		let password = (document.querySelector("#passwordfield") as HTMLInputElement).value

		let request : RequestInit = {
			method: "POST",
			body: JSON.stringify( { name, password } ),
			headers: {"Content-Type": "application/json"}
		}

		fetch("/api/v1/login", request)
			.then( response => {
				if (!response.ok) {
					console.log("wrong name or password")
					return
				}
				window.location.href = "/dashboard.html"
			})
			.catch( () => {
				console.log("error")
			})
	})
}